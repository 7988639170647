export default [
  {
    title: 'Activation de compte',
    route: 'espace-admin.activateaccount',
    icon: 'CheckSquareIcon',

  },
  {
    title: 'Tableau de bord',
    route: 'espace-admin.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Type d\'expert',
    route: 'espace-admin.typeexpert',
    icon: 'CheckSquareIcon',

  },
  {
    title: 'Type d\'expertise',
    route: 'espace-admin.typeexpertise',
    icon: 'CheckSquareIcon',

  },
  {
    title: 'Devise',
    route: 'espace-admin.devise',
    icon: 'DollarSignIcon',

  },
  {
    title: 'Document',
    route: 'espace-admin.document',
    icon: 'FileTextIcon',

  },
  {
    title: 'Documenthèque',
    icon: 'ListIcon',
    children: [
      {
        title: 'Catégorie Documentation',
        route: 'espace-admin.categoriedocumentation',
        icon: 'FileTextIcon',
      },
      {
        title: 'Documentation',
        route: 'espace-admin.documentation',
        icon: 'FileTextIcon',

      },

    ],
  },

  {
    title: 'Données géographiques',
    route: 'espace-admin.pays',
    icon: 'GlobeIcon',

  },
  {
    title: 'Commentaire',
    route: 'espace-admin.comment',
    icon: 'MessageCircleIcon',

  },
  {
    title: 'Unité de Mesure',
    route: 'espace-admin.unittype',
    icon: 'AnchorIcon',

  },
  {
    title: 'Modèle de camion',
    route: 'espace-admin.transport',
    icon: 'TruckIcon',

  },
  {
    title: 'Marchandise',
    route: 'espace-admin.marchandise',
    icon: 'ShoppingBagIcon',

  },
  {
    title: 'Gestion du tracking',
    route: 'espace-admin.tracking',
    icon: 'MapPinIcon',

  },
  {
    title: 'Statistiques',
    route: 'espace-admin.statistiques',
    icon: 'TrendingUpIcon',

  },

  {
    title: 'Gestion des utilisateurs',
    route: 'espace-admin.manage.user',
    icon: 'UsersIcon',

  },
  {
    title: 'Bilan des expertises',
    route: 'espace-admin.bilan',
    icon: 'UsersIcon',

  },
  {
    title: 'Validations des offres',
    route: 'espace-admin.offers',
    icon: 'BriefcaseIcon',
  },

]
